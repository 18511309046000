<template>
  <v-card flat class="transparent mx-auto mb-12" width="960">
    <h5 class="mb-12">
      <v-icon>$pineapple</v-icon>
      <small style="color: #092">pineapple.net.au</small>
    </h5>

    <EditPopupMessages
      v-if="ready"
      :messages.sync="messages"
    />

    <v-row justify="center">
      <v-btn @click="save" dark class="primary">
        update/save
      </v-btn>
    </v-row>
  </v-card>
</template>

<script>

export default {
  name: 'CheckAddressMessages',

  components: {
    EditPopupMessages: () => import('@/components/inputs/EditPopupMessages.vue')
  },

  data: () => ({
    messages: null,
    ready: false
  }),

  watch: {
    messages: {
      deep: true,
      handler (data) {
        // console.log('Messages changed:\n', data)
      }
    }
  },

  methods: {
    getData (data) {
      this.messages = data
      this.ready = true
    },

    save () {
      this.__updatePineappleCheckAddressMessages(this.messages, this.saveCallback)
    },

    saveCallback (response) {
      // console.log(response)
    }
  },

  created () {
    this.__getPineappleCheckAddressMessages(this.getData)
  }
}
</script>
